<template>
  <div class="wrapper">
    <side-bar :background-color="sidebarBackground" class="d-none d-lg-block">
      <template #links>
        <template v-for="item in navigationLinks" :key="item.path">
          <sidebar-item
            v-if="$h.hasRoles(item.Role)"
            :link="{
              name: $t.translate(item.Name),
              icon: item.Icon,
              path: item.Path,
              indexes: item.Indexes,
            }"
          />
        </template>
      </template>
    </side-bar>
    <div
      class="main-content"
      :data="sidebarBackground"
      v-loading="$h.isSubmitting() || $h.isFetching()"
    >
      <dashboard-navbar class=""></dashboard-navbar>

      <div>
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
<script>
import DashboardNavbar from "./DashboardNavbar.vue";
import { extendedConfigs } from "@/utils/configs";
import { ref } from "vue";
import { store } from "@/store";

export default {
  components: {
    DashboardNavbar,
  },
  setup() {
    let navigationList = store.getters["config/getData"]?.envConf?.navigationList;
    let navigationLinks = [];
    _.each(extendedConfigs.navigationLinks, function (v) {
      var obj = _.find(navigationList, ["Path", v.Path]);
      if (obj) {
        navigationLinks.push(obj);
      } else {
        navigationLinks.push(v);
      }
    });

    let sidebarBackground = ref("vue");
    return { navigationLinks, sidebarBackground };
  },
};
</script>
<style scoped>
.main-content {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
</style>
